import { NextRouter, useRouter } from "next/router";
import React from "react";
import { Spinner } from "reactstrap";
import { persistQueryParams } from "../utils/pageRouter";

type Journeys =
  | "venues"
  | "virtual"
  | "live"
  | "products"
  | "reservations"
  | "booking"
  | "type"
  | "error"
  | "default";

type JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string,
  chooseTypeInUrl?: string
) => void;

/**
 * path[7] - advisorId
 * path[5] - productId
 * path[3] - venueId
 * path[1] - BWID
 */
const venuesJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string,
  chooseTypeInUrl?: string
) => {
  if (path[7] && path[5] && path[3]) {
    router.replace(
      `/[BWID]${
        chooseTypeInUrl ?? ""
      }/venues/[venueId]/products/[productId]/advisors/[advisorId]${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/venues/${path[3]}/products/${
        path[5]
      }/advisors/${path[7]}${queryParams}`,
      {
        shallow: true,
      }
    );
  } else if (
    (path[6] === "addons" || path[6] === "slots") &&
    path[5] &&
    path[3]
  ) {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/venues/[venueId]/products/[productId]/${
        path[6]
      }${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/venues/${path[3]}/products/${
        path[5]
      }/${path[6]}${queryParams}`,
      {
        shallow: true,
      }
    );
  } else if (path[3]) {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/venues/[venueId]/products${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/venues/${
        path[3]
      }/products${queryParams}`,
      {
        shallow: true,
      }
    );
  } else {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/venues${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/venues${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

/**
 * path[3] - reservationId
 * path[1] - BWID
 */
const reservationsJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string
) => {
  if (path[3] && path[4] === "details") {
    router.replace(
      `/[BWID]/reservations/[reservationId]/details${queryParams}`,
      `/${path[1]}/reservations/${path[3]}/details${queryParams}`,
      {
        shallow: true,
      }
    );
  } else if (path[3] && path[4] === "confirmation") {
    router.replace(
      `/[BWID]/reservations/[reservationId]/confirmation${queryParams}`,
      `/${path[1]}/reservations/${path[3]}/confirmation${queryParams}`,
      {
        shallow: true,
      }
    );
  } else if (path[3] && path[4] === "payment") {
    router.replace(
      `/[BWID]/reservations/[reservationId]/payment${queryParams}`,
      `/${path[1]}/reservations/${path[3]}/payment${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

/**
 * path[3] - bookingId
 * path[1] - BWID
 */
const bookingJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string
) => {
  if (path[3] && path[4] === "cancellation") {
    router.replace(
      `/[BWID]/booking/[bookingId]/cancellation${queryParams}`,
      `/${path[1]}/booking/${path[3]}/cancellation${queryParams}`,
      {
        shallow: true,
      }
    );
  } else if (path[3]) {
    router.replace(
      `/[BWID]/booking/[bookingId]${queryParams}`,
      `/${path[1]}/booking/${path[3]}${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

const defaultJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string
) => {
  const { from, BWID } = router.query;

  if (path[1] === "" && !path[2]) {
    router.replace(
      `/${BWID}${from || "/venues"}${queryParams}`,
      `/${BWID}${from || "/venues"}${queryParams}`,
      {
        shallow: true,
      }
    );
  } else {
    router.replace(
      `/${BWID}${from || "/venues"}${queryParams}`,
      `/${path[1]}${from || "/venues"}${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

const errorJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string
) => {
  router.replace(
    `/[BWID]/error${queryParams}`,
    `/${path[1]}/error${queryParams}`,
    {
      shallow: true,
    }
  );
};

const defaultTypeJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string
) => {
  const { from, BWID } = router.query;

  if (path[1] === "" && !path[2]) {
    router.replace(
      `/${BWID}${from || "/type"}${queryParams}`,
      `/${BWID}${from || "/type"}${queryParams}`,
      {
        shallow: true,
      }
    );
  } else {
    router.replace(
      `/[BWID]${from || "/type"}${queryParams}`,
      `/${path[1]}${from || "/type"}${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

/**
 * path[4] - productId
 * path[3] - products
 * path[2] - virtual
 * path[1] - BWID
 */
const virtualProductsJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string,
  chooseTypeInUrl?: string
) => {
  if ((path[5] === "slots" || path[5] === "addons") && path[4] && path[3]) {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/virtual/products/[productId]/${
        path[5]
      }${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/virtual/products/${path[4]}/${
        path[5]
      }${queryParams}`,
      {
        shallow: true,
      }
    );
  } else {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/virtual/products${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/virtual/products${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

/**
 * path[3] - details
 * path[2] - productId
 * path[1] - BWID
 */
const liveChatJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string,
  chooseTypeInUrl?: string
) => {
  if (path[3] === "details" && path[2]) {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/live/[productId]/${
        path[3]
      }${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/live/${path[2]}/${
        path[3]
      }${queryParams}`,
      {
        shallow: true,
      }
    );
  } else {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/live${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/live${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

/**
 * path[4] - addons || venues
 * path[3] - productId
 * path[2] - products
 * path[1] - BWID
 */
const productsBeforeStoreJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string,
  chooseTypeInUrl?: string
) => {
  if ((path[4] === "venues" || path[4] === "addons") && path[4] && path[3]) {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/products/[productId]/${
        path[4]
      }${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/products/${path[3]}/${
        path[4]
      }${queryParams}`,
      {
        shallow: true,
      }
    );
  } else {
    router.replace(
      `/[BWID]${chooseTypeInUrl ?? ""}/products${queryParams}`,
      `/${path[1]}${chooseTypeInUrl ?? ""}/products${queryParams}`,
      {
        shallow: true,
      }
    );
  }
};

const typeJourney: JourneysFn = (
  path: string[],
  router: NextRouter,
  queryParams: string
) => {
  path.splice(2, 1);

  const journey = path[2] as Journeys;
  const defaultJourneyString: Journeys = "default";

  const journeyFns: Partial<Record<Journeys, JourneysFn>> = {
    venues: venuesJourney,
    virtual: virtualProductsJourney,
    live: liveChatJourney,
    products: productsBeforeStoreJourney,
    default: defaultTypeJourney,
  };

  journeyFns[journey]
    ? journeyFns[journey]?.(path, router, queryParams, "/type")
    : journeyFns[defaultJourneyString]?.(path, router, queryParams);
};

export const Index = () => {
  const router = useRouter();
  const { path } = router.query;

  // path = :bwid/route1/:id1:/route2/:id2/route3/:id3
  const parts = ((path as string) || "").match(
    /([\w-]*)\/?([\w-]*)?\/?([\w-]*)\/?([\w-]*)?\/?([\w-]*)\/?([\w-]*)\/?([\w-]*)\/?([\w-]*)?/
  ) as string[];

  const queryParams = persistQueryParams(router);
  const journey = parts[2] as Journeys;
  const defaultJourneyString: Journeys = "default";

  const journeyFns: Record<Journeys, JourneysFn> = {
    venues: venuesJourney,
    virtual: virtualProductsJourney,
    live: liveChatJourney,
    products: productsBeforeStoreJourney,
    reservations: reservationsJourney,
    booking: bookingJourney,
    type: typeJourney,
    error: errorJourney,
    default: defaultJourney,
  };

  journeyFns[journey]
    ? journeyFns[journey](parts, router, queryParams)
    : journeyFns[defaultJourneyString](parts, router, queryParams);

  return (
    <div className={"w-100 p-4 text-center load-script"}>
      <Spinner />
    </div>
  );
};

export default Index;
